'use strict'
angular.module('cb.modals', [])
	.controller('MemoHistoryController', ["$scope", "$rootScope", "$modalInstance", "EnquiryModalService", "NotificationService", "IpadService", "data", function ($scope, $rootScope, $modalInstance, EnquiryModalService, NotificationService, IpadService, data) {
		"ngInject";
		$scope.init = function () {
			$scope.Id = data;
			$scope.MemoHistory = [];
			$scope.GettingMemoHistory();
			$scope.OutOfArea = false;
			$scope.MemoHistoryPageNumber = 1;
			$scope.MemoHistoryLimit = 5;
			$scope.MemoHistoryPagination = {};
		};

		$scope.$watch('MemoHistoryPageNumber', function () {
			$scope.GettingMemoHistory();
		});

		$scope.GettingMemoHistory = function () {

			var dataOut = {
				Pagination: {
					StartPage: $scope.MemoHistoryPageNumber - 1,
					PageSize: 5
				},
				Id: $scope.Id
			};

			$scope.gettingMemoHistoryPromise = ($rootScope.onLineFlag) ? EnquiryModalService.getMemoHistory(dataOut) : IpadService.getMemosByLead(dataOut);
			$scope.gettingMemoHistoryPromise.then(function (output) {
				$scope.MemoHistory = output.data != null ? output.data.memos : output;
				$scope.MemoHistoryPagination = output.data.Pagination;
			}, function (error) {
				NotificationService.alert('Enquiry Error', 'There was an error getting memos on this enquiry.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('NoteHistoryController', ["$scope", "$rootScope", "$modalInstance", "EnquiryModalService", "NotificationService", "IpadService", "data", function ($scope, $rootScope, $modalInstance, EnquiryModalService, NotificationService, IpadService, data) {

		$scope.init = function () {
			$scope.Id = data;
			$scope.NoteHistory = [];
			$scope.PageSize = 25;
			$scope.notesPage = 1;
			$scope.notesNextPage = false;
			$scope.GettingNoteHistory();
			$scope.isUserAdmin = $rootScope.User.Roles.filter(r => r.Name == 'System Administrator').length > 0;
			$scope.isUserAccounts = $rootScope.User.Roles.filter(r => r.Name == 'Accounts').length > 0;
			$scope.IsAuthorisedToDelete = $scope.isUserAdmin;// && $scope.isUserAccounts;
		};

		$scope.GettingNoteHistory = function () {
			// $scope.gettingNoteHistoryPromise = EnquiryModalService.getNoteHistory({ Id: $scope.Id, StartPage: $scope.filesPage, PageSize: $scope.PageSize });
			$scope.gettingNoteHistoryPromise = ($rootScope.onLineFlag) ? EnquiryModalService.getNoteHistory($scope.Id) : IpadService.getNotesOrderId($scope.Id);
			$scope.gettingNoteHistoryPromise.then(function (output) {
				$scope.NoteHistory = output.data != null && output.data.Notes != null ? output.data.Notes : output;
			}, function (error) {
				NotificationService.alert('Enquiry Error', 'There was an error issuing the enquiry.', 'error', {});
			});
		};

		$scope.PinNote = function (note) {
			var data = {
				Id: note.NoteId,
				Pinned: !note.Pinned
			};

			$scope.pinningNotePromise = EnquiryModalService.pinNote(data);
			$scope.pinningNotePromise.then((success) => {
				NotificationService.alert('Note Updated', 'This note was successfully ' + (data.Pinned ? 'pinned' : 'unpinned') + '.', 'success', {});
				$scope.GettingNoteHistory();
			}, (error) => {
				NotificationService.alert('Note Error', 'There was an error ' + (data.Pinned ? 'pinning' : 'unpinning') + ' this note.', 'error', {});
			});
		};
		$scope.DeleteNote = function (note) {
			
			var data = {
				Id: note.NoteId,
			};

			$scope.deletingNotePromise = EnquiryModalService.deleteNote(data);
			$scope.deletingNotePromise.then((success) => {
				NotificationService.alert('Note Deleted', 'This note was successfully deleted.', 'success', {});
				$scope.GettingNoteHistory();
			}, (error) => {
				NotificationService.alert('Note Error', 'There was an error deleting this note.', 'error', {});
			});
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('FileLogController', ["$scope", "$rootScope", "$modalInstance", "EnquiryModalService", "NotificationService", "$filter", "data", function ($scope, $rootScope, $modalInstance, EnquiryModalService, NotificationService, $filter, data) {

		$scope.init = function () {
			$scope.Id = data.id;
			$scope.RequestArea = data.RequestArea;
			$scope.CurrentProfile = data.CurrentProfile;
			$scope.FileLog = [];
			$scope.FilesPageNumber = 1;
			$scope.Pagination = {};
			$scope.isUserAdmin = $rootScope.User.Roles.filter(r => r.Name == 'System Administrator' || r.Name == 'Operations Manager' || r.Name == 'Sales Desk' || r.Name == 'Marketing' || r.Name == 'Accounts' || r.Name == 'Partnership').length > 0 ? true : false;
		};

		$scope.$watch('FilesPageNumber', function () {
			$scope.GetFiles();
		});

		$scope.GetFiles = function () {
			if ($rootScope.SynoSid != "") {
				// SBLINDSUAT
				$scope.GettingFilesPromise = EnquiryModalService.getFiles({
					CurrentProfile: $scope.CurrentProfile,
					Sid: $rootScope.SynoSid,
					Archived: false,
					Pagination: {
						StartPage: $scope.FilesPageNumber - 1,
						PageSize: 10
					},
					RequestArea: $scope.RequestArea
				});
				$scope.GettingFilesPromise.then(function (output) {
					$scope.FileLog = output.data.Files;
					if ($scope.FileLog != null) {
						$scope.FileLog.forEach((item) => {
							item.FileType = $filter('fileType')(item.Extension);
						})
					}
					$scope.Pagination = output.data.Pagination;
				}, function (error) {
					NotificationService.alert('Files Failed', 'Failed to get the files.', 'error', {});
				});
			}
			else {
				NotificationService.alert('Authorization Error', 'User has not been given permision to use File Storage', 'error', {});
				$scope.close();
			}
		};

		$scope.decrement = function (pageNumber) {
			$scope[pageNumber]--;
		};

		$scope.increment = function (pageNumber) {
			$scope[pageNumber]++;
		};

		$scope.deleteFile = function (FileName, FileType) {
			var data = {
				Sid: $rootScope.SynoSid,
				CurrentProfile: $scope.CurrentProfile,
				SelectedFileName: FileName,
				SelectedFileType: FileType,
				RequestArea: $scope.RequestArea
			};

			//alert(JSON.stringify(data));

			$scope.FileDeletePromise = EnquiryModalService.deleteFile(data);
			$scope.FileDeletePromise.then(function (output) {
				$rootScope.NumberOfFiles = $rootScope.NumberOfFiles - 1;
				$scope.close();	
			}, function (err) {
				NotificationService.alert(' File Failed', 'Failed to delete the File' + ".", 'error', {});

			});
		};

		$scope.downloadFile = function (FileName, FileType) {
			var data = {
				Sid: $rootScope.SynoSid,
				CurrentProfile: $scope.CurrentProfile,
				SelectedFileName: FileName,
				SelectedFileType: FileType,
				RequestArea: $scope.RequestArea
			};
			
			$scope.FileDownloadPromice = EnquiryModalService.downloadFile(data);
			$scope.FileDownloadPromice.then(function (output) {
				var string = 'data:' + output.data.FileType + ';base64,' + output.data.Base64;
				var byteString = window.atob(string.split(',')[1]);
				var ab = new ArrayBuffer(byteString.length);
				var ia = new Uint8Array(ab);
				for (var i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				};

				var type = $rootScope.MimeArray.find(arr => arr.ext === output.data.FileType);

				var blob = new Blob([ab], {
					type: type.mime
				});

				saveAs(blob, FileName + FileType);
			}, function (err) {
				NotificationService.alert(' File Failed', 'Failed to download the File' + ".", 'error', {});

			});
		}

		$scope.openFileInNewTab = function (FileName, FileType) {
			var data = {
				Sid: $rootScope.SynoSid,
				CurrentProfile: $scope.CurrentProfile,
				SelectedFileName: FileName,
				SelectedFileType: FileType,
				RequestArea: $scope.RequestArea
			};
			
			$scope.FileDownloadPromice = EnquiryModalService.downloadFile(data);
			$scope.FileDownloadPromice.then(function (output) {
				var string = 'data:' + output.data.FileType + ';base64,' + output.data.Base64;
				var byteString = window.atob(string.split(',')[1]);
				var ab = new ArrayBuffer(byteString.length);
				var ia = new Uint8Array(ab);
				for (var i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				};

				var type = $rootScope.MimeArray.find(arr => arr.ext === output.data.FileType);

				var blob = new Blob([ab], {
					type: type.mime
				});

				// This maybe what they want!!! KH

				//switch (true) {
				//	case $rootScope.User.Roles.filter(r => r.Name == 'Sales Person').length > 0:
				//	case $rootScope.User.Roles.filter(r => r.Name == 'Fitter').length > 0:
				//	case $rootScope.User.Roles.filter(r => r.Name == 'Sales Person/Fitter').length > 0:
				//	case $rootScope.User.Roles.filter(r => r.Name == 'Sales Manager').length > 0:
				//	case $rootScope.User.Roles.filter(r => r.Name == 'Field Manager').length > 0:
				//		// New tab.
				//		var fileUrl = URL.createObjectURL(blob);
				//		window.open(fileUrl, '_blank');
				//		break;
				//	case $rootScope.User.Roles.filter(r => r.Name == 'Contracts').length > 0:
				//	case $rootScope.User.Roles.filter(r => r.Name == 'Operations Manager').length > 0:
				//		// Download.
				//		saveAs(blob, FileName + FileType);
				//		break;
				//	default:
				//		// Download.
				//		saveAs(blob, FileName + FileType);
				//		break;
				//}

				// Signed off 28th Sept this

				var fileUrl = URL.createObjectURL(blob);
				window.open(fileUrl, '_blank');


			}, function (err) {
				NotificationService.alert(' File Failed', 'Failed to open the File' + ".", 'error', {});

			});
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('ActivityLogController', ["$scope", "$modalInstance", "ActivityLogService", "NotificationService", "Id", function ($scope, $modalInstance, ActivityLogService, NotificationService, Id) {

		$scope.init = function () {
			$scope.Id = Id;
			$scope.ActivityLog = [];
			$scope.ActivityLogPageNumber = 1;
			$scope.ActivityLogLimit = 10;
			$scope.ActivityLogPagination = {};
		};

		$scope.$watch('ActivityLogPageNumber', function () {
			$scope.GettingActivityLog();
		});

		$scope.GettingActivityLog = function () {
			var data = {
				Pagination: {
					StartPage: $scope.ActivityLogPageNumber - 1,
					PageSize: 10
				},
				Id: $scope.Id
			};

			$scope.gettingActivityLogPromise = ActivityLogService.getActivityLog(data);
			$scope.gettingActivityLogPromise.then(function (output) {
				$scope.ActivityLog = output.data.Logs;
				$scope.ActivityLogPagination = output.data.Pagination;
			}, function (error) {
				NotificationService.alert('Activity Error', 'There was an getting the activity log.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('AddNoteController', ["$scope", "$rootScope", "$modalInstance", "EnquiryModalService", "NotificationService", "data", "IpadService", function ($scope, $rootScope, $modalInstance, EnquiryModalService, NotificationService, data, IpadService) {

		$scope.init = function () {
			$scope.Note = data;
		};

		$scope.AddNote = function () {
			if (!$rootScope.onLineFlag) {
				$scope.Note.DateCreated = new moment().toString();
			}

			$scope.AddingNotePromise = ($rootScope.onLineFlag) ? EnquiryModalService.addNote($scope.Note) : IpadService.addNote($scope.Note);
			$scope.AddingNotePromise.then(function (output) {
				NotificationService.alert('Note Added', 'Added note successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Note Failed', 'Failed to add the note.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])

	.controller('EditNoteController', ["$scope", "$rootScope", "$modalInstance", "EnquiryModalService", "NotificationService", "data", "IpadService", function ($scope, $rootScope, $modalInstance, EnquiryModalService, NotificationService, data, IpadService) {

		$scope.init = function () {
			$scope.Note = data;
		};

		$scope.AddNote = function () {
			if (!$rootScope.onLineFlag) {
				$scope.Note.DateCreated = new moment().toString();
			}

			$scope.AddingNotePromise = ($rootScope.onLineFlag) ? EnquiryModalService.addNote($scope.Note) : IpadService.addNote($scope.Note);
			$scope.AddingNotePromise.then(function (output) {
				NotificationService.alert('Note Added', 'Added note successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Note Failed', 'Failed to add the note.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('QuoteOptionsController', ["$scope", "$rootScope", "$modalInstance", "EnquiryModalService", "NotificationService", "data", function ($scope, $rootScope, $modalInstance, EnquiryModalService, NotificationService, data) {

		$scope.init = function () {
			$scope.Id = data.id;
			$scope.Type = data.type;
			$scope.TypeDesc = data.type == "K" ? "Key Benefits" : data.type == "D" ? "Disclaimers" : "Home Automation Options";
			$scope.quoteOptions = { Id: 0, LeadId: 0, BoolValue: false };
			$scope.GetQuoteOptions();
		};

		$scope.GetQuoteOptions = function () {

			$scope.GetQuoteOptionsPromise = EnquiryModalService.getQuoteOptions($scope.Id, $scope.Type);
			$scope.GetQuoteOptionsPromise.then(function (output) {
				$scope.QuoteOptions = output.data != null ? output.data.QuoteOptions : output;
				$scope.QuoteOptions.LeadId = $scope.Id;
			}, function (error) {
				NotificationService.alert('Quote Options Error', 'There was an error getting the quote options.', 'error', {});
			});
		};

		$scope.save = function () {

			//if ($scope.QuoteOptions.Id > 0) { // Update
			$scope.saveQuoteOptionsPromise = EnquiryModalService.updateQuoteOptions($scope.QuoteOptions);
			//} else { // Create
			//	$scope.saveQuoteOptionsPromise = EnquiryModalService.addQuoteOptions($scope.QuoteOptions);
			//};

			$scope.saveQuoteOptionsPromise.then(function (output) {
				NotificationService.alert('Quote Options Saved', 'Quote Options changes were made.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Quote Options Error', 'There was an error saving the home quote options.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])

	.controller('IFrameController', ["$scope", "$sce", "$rootScope", "$modalInstance", "EnquiryModalService", "NotificationService", "data", "$http", function ($scope, $sce, $rootScope, $modalInstance, EnquiryModalService, NotificationService, data, $http) {
		
		$scope.init = function () {

			$scope.authenticateAndLoadIframe();
		};


		// Authenticate and store token in memory
		$scope.authenticateAndLoadIframe = function () {

			var portalUrl = data.portalUrl.replace(/"/g, "");

			// Get Auth Token
			const authApiUrl = portalUrl + "api/auths";
			var portalCredentials = data.portalCredentials.replace(/"/g, '').split('|');
			const credentials = {
				username: portalCredentials[0],
				password: portalCredentials[1]
			};

			$http.post(authApiUrl, credentials)
				.then(function (response) {
					const token = response.data.token; // Get the JWT token

					// Build the url
					var pageUrl = '';
					if (data.type == 'C') {
						pageUrl = portalUrl + "main/commissioncalculator/" + data.id + "/" + data.option;
					}
					else if (data.type == 'P') {
						pageUrl = portalUrl + "main/pricingcalculator/" + data.id;
					}
					else if (data.type == 'Q') {
						pageUrl = portalUrl + "main/quotebuilder/" + data.id + "/" + data.option;
					}
					else if (data.type == 'M-Data') {
						//apiUrl = portalUrl + "api/pricingmatrixs?page=1&pageSize=5"
						pageUrl = portalUrl + "admin/pricingmatrix";
					}
					else if (data.type == 'M-Options') {
						pageUrl = portalUrl + "admin/pricingrecommendedoption";
					}
					else if (data.type == 'M-Charges') {
						pageUrl = portalUrl + "admin/additionalcharge";
					}

					// Append the token as a URL parameter
					pageUrl += '?sbtoken=' + encodeURIComponent(token);

					// Trust the URL, and use the pageUrl
					$scope.iframeSrc = $sce.trustAsResourceUrl(pageUrl);
				})
				.catch(function (error) {
					console.error("Authentication failed", error);
				});
		};

		$scope.save = function () {
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])

	.controller('CreateAlertModalController', ["$scope", "$modalInstance", "EnquiryModalService", "StaffService", "NotificationService", "data", function ($scope, $modalInstance, EnquiryModalService, StaffService, NotificationService, data) {

		$scope.init = function () {
			$scope.Data = data;
			$scope.staffList = [];
			getListOfUsers();
		};

		function getListOfUsers() {
			$scope.gettingListOfStaffPromise = StaffService.getAllStaff();
			$scope.gettingListOfStaffPromise.then(staff => {
				$scope.staffList = staff.data.StaffList;
			}, err => {
				NotificationService.alert('Staff List Failed', 'Failed to retireve your list of staff members.', 'error', {});
			});
		};

		$scope.createAlert = function () {
			$scope.creatingAlertPromise = EnquiryModalService.createAlert({
				Alert: $scope.Data
			});
			$scope.creatingAlertPromise.then(success => {
				NotificationService.alert('Alert Created', 'Alert was successfully created.', 'success', {});
				$modalInstance.close();
			}, err => {
				NotificationService.alert('Alert Failed', 'Failed to create the alert.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('SendLetterController', ["$scope", "$rootScope", "$modalInstance", "NotificationService", "EnquiryModalService", "LetterGenerationService", "data", "config", function ($scope, $rootScope, $modalInstance, NotificationService, EnquiryModalService, LetterGenerationService, data, config) {
		$scope.init = function () {
			$scope.template = {};
			$scope.downloadCompleted = false;
			$scope.data = data;
			$scope.LetterType = config.LetterType;
			$scope.hideCloudTemplates = false;
			$scope.SynoId = $rootScope.SynoSid || '';
			$scope.CloudTemplates = [];
			$scope.FilesPageNumber = 1;
			$scope.Pagination = {};
			$scope.getLetterTemplates();
		};

		$scope.$watch('FilesPageNumber', function () {
			$scope.getLetterTemplates();
		});

		$scope.Increment = function () {
			$scope.FilesPageNumber++;
			$scope.getLetterTemplates();
		}

		$scope.Decrement = function () {
			$scope.FilesPageNumber--;
			$scope.getLetterTemplates();
		}

		$scope.getLetterTemplates = function () {
			$scope.getLetterTemplatesPromice = LetterGenerationService.GetLetterTemplates();
			$scope.getLetterTemplatesPromice.then((output) => {
				var Templatelist = output.data;
				$scope.LetterTemplates = [];
				angular.forEach(Templatelist, (item, key) => {
					if (item.Type.toLowerCase() == data.ObjectType.toLowerCase()) {
						$scope.LetterTemplates.push(item);
					};
				});
				if ($scope.LetterTemplates.length > 0)
					$scope.data.TemplateId = $scope.LetterTemplates[0].Id;
			}, (error) => {
				NotificationService.alert('Failed to get Letter Templates', 'there was a error when getting Letter Templates', 'error', {})
			})
			if ($scope.SynoId != "") { //this means the user is logged into the cloud storage
				$scope.hideCloudTemplates = true;
				$scope.GettingFilesPromise = EnquiryModalService.getLetterTemplatesOnCloud({
					Sid: $scope.SynoId,
					Pagination: {
						StartPage: $scope.FilesPageNumber - 1,
						PageSize: 10
					},
					LetterLocation: $scope.data.LetterLocation,
					ViewLocation: $scope.data.ViewLocation
				});
				$scope.GettingFilesPromise.then(function (output) {
					$scope.CloudTemplates = output.data.Files;
					$scope.Pagination = output.data.Pagination;
				}, function (error) {
					NotificationService.alert('Files Failed', 'Failed to retrieve the letter templates.', 'error', {});
				});
			}
		}

		$scope.SelectLetterFromCloud = function (FileName, FileType) { // function to move the selected file into $scope.template
			var data = {
				Sid: $rootScope.SynoSid,
				CurrentProfile: "",
				SelectedFileName: FileName,
				SelectedFileType: FileType,
				LetterLocation: $scope.data.LetterLocation,
				ViewLocation: $scope.data.ViewLocation
			};
			$scope.FileDownloadPromice = EnquiryModalService.downloadTemplate(data);
			$scope.FileDownloadPromice.then(function (output) {
				var string = 'data:' + output.data.FileType + ';base64,' + output.data.Base64;
				var byteString = window.atob(string.split(',')[1]);
				var ab = new ArrayBuffer(byteString.length);
				var ia = new Uint8Array(ab);
				for (var i = 0; i < byteString.length; i++) {
					ia[i] = byteString.charCodeAt(i);
				};

				var type = $rootScope.MimeArray.find(arr => arr.ext === output.data.FileType);

				var blob = new Blob([ab], {
					type: type.mime
				});

				// move file to $scope.template
				$scope.template.file = new File([blob], output.data.FileName + output.data.FileType);
				$scope.SendLetter();

			}, function (err) {
				NotificationService.alert(' File Failed', 'Failed to download the File' + ".", 'error', {});

			});
		}

		$scope.SendLetter = function () {
			$scope.LetterPromice = LetterGenerationService.MakeLetter($scope.data, $scope.template.file);
			$scope.LetterPromice.then((output) => {
				$scope.downloadCompleted = true;
				var file = new Blob([output.data])
				saveAs(file, $scope.template.file.name);
				$modalInstance.close();
			}, (error) => {
				NotificationService.alert('Letter Download failed', 'There was a error when try to make or download the letter.', 'error', {})
			})
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('SendBrochureController', ["$scope", "$modalInstance", "EnquiryModalService", "NotificationService", "Id", function ($scope, $modalInstance, EnquiryModalService, NotificationService, Id) {

		$scope.init = function () {
			$scope.Id = Id;
			$scope.template = {};
		};

		$scope.SendBrochure = function () {
			$scope.SendingBrochurePromise = EnquiryModalService.sendBrochure($scope.Id, $scope.template);
			$scope.SendingBrochurePromise.then(function (output) {
				NotificationService.alert('Brochure Sent', 'Sent brochure successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Brochure Send Failure', 'Failed to send the brochure.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('SendQuoteController', ["$scope", "$modalInstance", "EnquiryModalService", "NotificationService", "Id", "$http", "ApiService", "$timeout", function ($scope, $modalInstance, EnquiryModalService, NotificationService, Id, $http, ApiService, $timeout) {

		$scope.init = function () {
			$scope.Id = Id;
			$scope.template = {};
		};

		$scope.SendQuote = function () {

			var fd = new FormData();

			//var databaseId = $scope.uploadForm.databaseselect.$viewValue.CorbacsId;

			//Take the first selected file

			var file = document.getElementById('fileUpload');
			if (file.files[0]) {
				fd.append("file", file.files[0]);
				fd.append("Id", $scope.Id);
				fd.append("Action", "SendQuote");
				//fd.append("databaseId", databaseId);


				$scope.fileUploadPromise = $http.post('../webapi/api/v1/file/sendQuote', fd, {
					withCredentials: true,
					headers: {
						'Content-Type': undefined
					},
					transformRequest: angular.identity,
					responseType: 'arraybuffer'
				});

				$scope.fileUploadPromise.success(function (data, status, headers, config) {

					$timeout(function () {

						var mine = headers('content-type');
						var blob = new Blob([data], {
							'type': mine
						});
						saveAs(blob, headers('content-disposition').replace('attachment; ', '').replace('filename=', ''));

						//Folloing method too can be used.
						//ApiService.downloadFile(headers('content-disposition').replace('attachment; ', '').replace('filename=', ''), headers('content-type'), data);

					}, 1000);
					NotificationService.alert('Quote Sent', 'Sent quote successfully.', 'success', {});
					$modalInstance.close();
				})
					.error(function (data, status, headers, config) {
						NotificationService.alert('Quote Send Failure', 'There was an error with your file upload, please try again.', 'error', {});
					});
			}
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('SendMemoController', ["$rootScope", "$scope", "$location", "$modalInstance", "EnquiryModalService", "NotificationService", "IpadService", "data", function ($rootScope, $scope, $location, $modalInstance, EnquiryModalService, NotificationService, IpadService, data) {

		$scope.init = function () {
			$scope.respondingToReminder = data.ReplyingToReminder ? true : false;
			$scope.Memo = {
				Lead: data.ReplyingToReminder ? data.Reminder.SentFromId : data.SystemId,
				Message: "",
				Subject: "",
				MemoType: data.Type,
				SentFromPath: data.ReplyingToReminder ? data.Reminder.SentFromPath : data.SentPath,
				SentFromId: data.ReplyingToReminder ? data.Reminder.SentFromId : data.SentId,
				Recipients: [],
				Parent: data.ReplyingToReminder ? data.Reminder.Parent : null,
				LeadName: data.Name
			};
			$scope.Memo.MemoType == 'Reminder' ? $scope.Memo.Subject = 'Reminder regarding customer ' + data.Name + ' Ref. #: ' + data.Id : null;
			$scope.StaffList = [];
			$scope.GroupList = [];
			$scope.NonrecipientList = [];
			$scope.Type = data.Type;
			$scope.getStaff();
			$scope.getGroup();
			if (data.Samples) { // SENDING SAMPLES TO FACTORY
				$scope.Samples = data.Samples
				//$scope.Memo.Recipients = //find factory
				$scope.Memo.Subject = 'Sample order for customer ' + data.Name + ' Ref. #' + data.Id;
				for (i = 0; i < $scope.Samples.length; i++) {
					if ($scope.Samples[i].DateSent == null) // this means it has not been sent.
						$scope.Memo.Message = $scope.Memo.Message + ' \n <p>'
							+ "Sample " + (i + 1) + ': \n <br>'
							+ "Date Requested: " + moment($scope.Samples[i].DateRequested).format('DD-MM-YY') + ' \n <br>'
							+ 'Product: ' + $scope.Samples[i].ProductString + ' \n <br>'
							+ 'Fabric: ' + $scope.Samples[i].FabricString + ' \n <br>'
							+ 'Rail Colour: ' + $scope.Samples[i].RailColourString + ' \n <br>'
							+ 'Additional Notes: ' + $scope.Samples[i].Notes + ' \n <br>';
				}
			}
		};

		$scope.getStaff = function () {
			$scope.GettingStaffListPromise = ($rootScope.onLineFlag) ? EnquiryModalService.getStaff() : IpadService.getAllStaff();
			$scope.GettingStaffListPromise.then(function (output) {
				$scope.StaffList = ($rootScope.onLineFlag) ? output.data.StaffList : output;
				if ($scope.StaffList != null) {
					$scope.StaffList.forEach(function (Name) {
						var item = {};
						item.Id = Name.Id;
						item.FirstName = Name.FirstName;
						item.LastName = Name.LastName;
						item.Email = Name.Email;
						item.Type = 'User';
						$scope.NonrecipientList.push(item);
					});
				}
				if (data.Samples)
					$scope.AddStaff($scope.NonrecipientList.find((rec) => { return rec.Email == 'cbdmstock@gmail.com' }), 1);
				if ($scope.respondingToReminder)
					getOldMemo();
				$rootScope.$broadcast('RemindersUpdated', {});
			}, function (error) {
				NotificationService.alert('Staff Error', 'Failed to retrieve the list of staff.', 'error', {});
			});
		};
		$scope.getGroup = function () {
			var data = {}
			data.Pagination = {};
			data.Pagination.StartPage = 0;
			data.Pagination.PageSize = 100;
			$scope.GettingGroupListPromise = ($rootScope.onLineFlag) ? EnquiryModalService.getGroup(data) : IpadService.getAllGroups();
			$scope.GettingGroupListPromise.then(function (output) {
				$scope.GroupList = ($rootScope.onLineFlag) ? output.data.UserGroupList : output;
				if ($scope.GroupList != null) {
					$scope.GroupList.forEach(function (Name) {
						var item = {
							Id: Name.Id,
							FirstName: Name.GroupName,
							Type: 'Group'
						}
						$scope.NonrecipientList.push(item);
					});

				}
			}, function (error) {
				NotificationService.alert('Group Error', 'Failed to retrieve the list of User group.', 'error', {});
			});
		};

		$scope.AddStaff = function (name, index) {
			$scope.Memo.Recipients.push(name);
			$scope.NonrecipientList.splice($scope.NonrecipientList.indexOf(name), 1);
		};

		$scope.RemoveStaff = function (name, index) {
			$scope.NonrecipientList.push(name);
			$scope.Memo.Recipients.splice($scope.Memo.Recipients.indexOf(name), 1);
		};

		$scope.SendMemo = function () {
			$scope.Memo.Recipients = $scope.Memo.Recipients;//.map(x => x.Id);
			if (!$rootScope.onLineFlag) {
				$scope.Memo.Sent = moment().toString();
			}
			$scope.AddingMemoPromise = ($rootScope.onLineFlag) ? EnquiryModalService.addMemo($scope.Memo) : IpadService.addMemo($scope.Memo);
			$scope.AddingMemoPromise.then(function (output) {
				NotificationService.alert($scope.Type || 'Response' + ' Sent', $scope.Type || 'Response' + ' sent successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert($scope.Type || 'Response' + ' Failed', 'Failed to send the ' + $scope.Type || 'Response' + ".", 'error', {});
			});
		};

		function getOldMemo() {
			$scope.gettingOldMemoPromise = +($rootScope.onLineFlag) ? EnquiryModalService.getMemo($scope.Memo.Parent) : IpadService.getMemoByKey($scope.Memo.Parent);
			$scope.gettingOldMemoPromise.then(output => {
				$scope.oldMemo = ($rootScope.onLineFlag) ? output.data : output;
				$scope.cleanHTML();
				$rootScope.$broadcast('appointmentRead', {});
				$scope.Memo.Subject = 'Replying to ' + $scope.oldMemo.Subject;

				if ($scope.respondingToReminder)
					$scope.AddStaff($scope.NonrecipientList.find((rec) => { return rec.FirstName + ' ' + rec.LastName == $scope.oldMemo.From }), 1);
			}, err => {
				NotificationService.alert('Parent Memo Failed', 'Failed to retrieve the details of the parent memo.', 'error', {});
			});
		};

		$scope.cleanHTML = function () {
			var html = "<html><head></head><body class=\"form-control\" style=\"font-family:Arial !important;font-size-12 !important;\">" + $scope.oldMemo.MemoDetails; + "</body></html>";
			var iframe = document.getElementById('frame');
			iframe.contentWindow.document.open();
			iframe.contentWindow.document.write(html);
			iframe.contentWindow.document.close();
		}

		$scope.goToOrigin = function () {
			if ($scope.respondingToReminder)
				$location.url('/' + ($rootScope.User.Roles.filter(r => r.Name == 'Sales Person').length > 0 ? 'lead-details' : 'sales-order') + '/' + $scope.Memo.SentFromId);
			else
				$location.url('/enquiry-details/' + $scope.Memo.Lead);
			$scope.close();
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('AppointmentsLogController', ["$scope", "$location", "$modalInstance", "EnquiryModalService", "NotificationService", "data", function ($scope, $location, $modalInstance, EnquiryModalService, NotificationService, data) {

		$scope.init = function () {
			$scope.Id = data;
			$scope.AppointmentsLog = [];
			$scope.GettingAppointmentsLog();
		};

		$scope.GettingAppointmentsLog = function () {
			$scope.gettingAppointmentsLogPromise = EnquiryModalService.getAppointmentsLog($scope.Id);
			$scope.gettingAppointmentsLogPromise.then(function (output) {
				$scope.AppointmentsLog = output.data.Appointments;
			}, function (error) {
				NotificationService.alert('Appointments Error', 'There was an getting the appointments log.', 'error', {});
			});
		};

		$scope.goToAppointments = function () {
			$location.url('/appointments');
			$scope.close();
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('NewMessageController', ["$scope", "$rootScope", "$modalInstance", "EnquiryModalService", "NotificationService", "IpadService", "Recipients", "Id", "ParentMemo", "LeadId", "Subject", "SentFromId", "Replies", function ($scope, $rootScope, $modalInstance, EnquiryModalService, NotificationService, IpadService, Recipients, Id, ParentMemo, LeadId, Subject, SentFromId, Replies) {
		$scope.init = function () {
			$scope.Reply = Replies;
			$scope.Memo = {
				Parent: ParentMemo,
				Message: "",
				Subject: Subject,
				SentFromId: SentFromId,
				Lead: LeadId,
				Recipients: Recipients
			};

			$scope.StaffList = [];
			$scope.GroupList = [];
			$scope.Recipients = [];
			$scope.NonrecipientList = [];
			$scope.getStaff();
			$scope.getGroup();


		};

		function SetReply(id, $scope) {
			if (Id != null) {
				AddFromId(id)
				$scope.Memo.Parent = ParentMemo;
			}
		}

		function AddFromId(id) {
			var name = $scope.NonrecipientList.find(function (Item) {
				return Item.Id == id;
			});
			$scope.AddStaff(name);
		}

		function SetReplyAll(Recipients, $scope) {
			if (Recipients != null) {
				Recipients.forEach(function (Name) {
					if (Name.recipientId != Id) {
						AddFromId(Name.recipientId)
					}
				});

				$scope.Memo.Parent = ParentMemo;
			}
		}
		$scope.getStaff = function () {
			$scope.GettingStaffListPromise = ($rootScope.onLineFlag) ? EnquiryModalService.getStaff() : IpadService.getAllStaff();
			$scope.GettingStaffListPromise.then(function (output) {
				$scope.StaffList = ($rootScope.onLineFlag) ? output.data.StaffList : output;
				if ($scope.StaffList != null) {
					$scope.StaffList.forEach(function (Name) {
						var item = {};
						item.Id = Name.Id;
						item.FirstName = Name.FirstName;
						item.LastName = Name.LastName;
						item.Email = Name.Email;
						item.Type = 'User';
						$scope.NonrecipientList.push(item);
					});

				}

				SetReply(Id, $scope);
				SetReplyAll(Recipients, $scope);
			}, function (error) {
				NotificationService.alert('Staff Error', 'Failed to retrieve the list of staff.', 'error', {});
			});
		};
		$scope.getGroup = function () {
			var data = {}
			data.Pagination = {};
			data.Pagination.StartPage = 0;
			data.Pagination.PageSize = 100;
			$scope.GettingGroupListPromise = ($rootScope.onLineFlag) ? EnquiryModalService.getGroup(data) : IpadService.getAllGroups();
			$scope.GettingGroupListPromise.then(function (output) {
				$scope.GroupList = ($rootScope.onLineFlag) ? output.data.UserGroupList : output;
				if ($scope.GroupList != null) {
					$scope.GroupList.forEach(function (Name) {
						var item = {};
						item.Id = Name.Id;
						item.FirstName = Name.GroupName;
						item.Type = 'Group';
						$scope.NonrecipientList.push(item);
					});

				}

			}, function (error) {
				NotificationService.alert('Group Error', 'Failed to retrieve the list of User group.', 'error', {});
			});
		};
		$scope.AddStaff = function (name, index) {
			$scope.Recipients.push(name);
			$scope.NonrecipientList.splice($scope.NonrecipientList.indexOf(name), 1);
		}
		$scope.RemoveStaff = function (name, index) {
			$scope.NonrecipientList.push(name);
			$scope.Recipients.splice($scope.Recipients.indexOf(name), 1);
		}

		$scope.SendMemo = function () {
			$scope.Memo.Recipients = $scope.Recipients;//.map(a => a.Id)
			$scope.AddingMemoPromise = ($rootScope.onLineFlag) ? EnquiryModalService.addMemo($scope.Memo) : IpadService.sendMemo($scope.Memo);
			$scope.AddingMemoPromise.then(function (output) {
				NotificationService.alert('Memo Sent', 'Memo sent successfully.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert(' Memo Failed', 'Failed to send the Memo' + ".", 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('AddFile', ["$scope", "$rootScope", "$modalInstance", "NotificationService", "EnquiryModalService", "data", function ($scope, $rootScope, $modalInstance, NotificationService, EnquiryModalService, data) {

		$scope.init = function () {
			if ($rootScope.SynoSid == "") {
				NotificationService.alert('Authorization Error', 'User has not been given permision to upload files', 'error', {});
				$scope.close();
			}
			$scope.CurrentProfile = data.CurrentProfile;
			$scope.RequestArea = data.RequestArea;
		};

		$scope.SendFile = function () {
			// SBLINDSUAT
			var file = document.getElementById('FileUpload');
			var contents = "";
			if (file.files.length > 0) {
				var count = -1;
				for (var i = 0; i < file.files.length; i++) {

					var uploadfile = file.files[i];
					var filename = uploadfile.name;
					var fields = filename.split('.');

					if (file.files[i].size <= 52428800) {//Currently limiting upload to 50MB
						var fr = new FileReader;
						fr.onload = (function (e) {
							count++;
							contents = e.target.result;
							var data;
							try {
								data = {
									CurrentProfile: $scope.CurrentProfile,
									FileExtension: $rootScope.MimeArray.find(arr => arr.mime === file.files[count].type).ext,
									FileName: file.files[count].name.replace(/\.[^/.]+$/, ""),
									RequestArea: $scope.RequestArea
								};
							}
							catch (e) {
								data = {
									CurrentProfile: $scope.CurrentProfile,
									FileExtension: $rootScope.MimeArray.find(arr => arr.ext === "." + fields[1]).ext,
									FileName: file.files[count].name.replace(/\.[^/.]+$/, ""),
									RequestArea: $scope.RequestArea
								};
							}
							//alert(JSON.stringify(data));
							var reader = new FileReader();
							reader.readAsDataURL(file.files[count]);
							reader.onload = function () {
								data.FileContent = reader.result.split(',')[1];

								//communicate with server
								$scope.saveFilePromise = EnquiryModalService.AddFiles(data);
								$scope.saveFilePromise.then(function (output) {
									if (output.data.renameError == true) {
										NotificationService.alert('File already exists', 'The file ' + data.FileName + data.FileExtension + ' already exists. Please rename the file, then reupload it.', 'error', {});
									}
									else {
										NotificationService.alert('File Uploaded', data.FileName + data.FileExtension + 'has been uploaded successfully.', 'success', {});
										if (count == file.files.length - 1)
											$scope.close();
									}
								}, function (err) {
									alert(JSON.stringify(err));
									NotificationService.alert(' File Failed', 'Failed to upload ' + data.FileName + data.FileExtension + ".", 'error', {});
								});
							}
						})
						fr.readAsArrayBuffer(file.files[i], i);
					}
					else {
						NotificationService.alert('Error: File size too big.', 'A file is over 50MB and cannot be uploaded. Please resize your file or contact an administrator.')
					}
				}
			}
			else {
				NotificationService.alert('Upload Failed', 'No file found to upload', 'error');
			}
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('AddSurveyFile', ["$scope", "$rootScope", "$modalInstance", "NotificationService", "EnquiryModalService", "data", function ($scope, $rootScope, $modalInstance, NotificationService, EnquiryModalService, data) {

		$scope.surveyGroupId = 0;

		$scope.init = function () {
			if ($rootScope.SynoSid == "") {
				NotificationService.alert('Authorization Error', 'User has not been given permision to upload files', 'error', {});
				$scope.close();
			}
			$scope.CurrentProfile = data.CurrentProfile;
			$scope.RequestArea = data.RequestArea;
			$scope.getGroup();
		};

		$scope.getGroup = function () {
			var data = {}
			data.Pagination = {};
			data.Pagination.StartPage = 0;
			data.Pagination.PageSize = 100;
			$scope.GettingGroupListPromise = ($rootScope.onLineFlag) ? EnquiryModalService.getGroup(data) : IpadService.getAllGroups();
			$scope.GettingGroupListPromise.then(function (output) {
				$scope.GroupList = ($rootScope.onLineFlag) ? output.data.UserGroupList : output;
				if ($scope.GroupList != null) {
					$scope.GroupList.forEach(function (Name) {
						if (Name.GroupName == "Survey/Remake Upload")
							$scope.surveyGroupId = Name.Id;
					});

				}

			}, function (error) {
				NotificationService.alert('Group Error', 'Failed to retrieve the list of User groups.', 'error', {});
			});
		};

		$scope.SendFile = function () {
			// SBLINDSUAT
			var file = document.getElementById('FileUpload');
			var contents = "";
			if (file.files.length > 0) {
				var count = -1;
				for (var i = 0; i < file.files.length; i++) {
					if (file.files[i].size <= 52428800) {//Currently limiting upload to 50MB
						var fr = new FileReader;
						fr.onload = (function (e) {
							count++;
							contents = e.target.result;
							var data = {
								CurrentProfile: $scope.CurrentProfile,
								FileExtension: $rootScope.MimeArray.find(arr => arr.mime === file.files[count].type).ext,
								FileName: file.files[count].name.replace(/\.[^/.]+$/, ""),
								RequestArea: $scope.RequestArea
							};

							var reader = new FileReader();
							reader.readAsDataURL(file.files[count]);
							reader.onload = function () {
								data.FileContent = reader.result.split(',')[1];

								//communicate with server
								$scope.saveFilePromise = EnquiryModalService.AddFiles(data);
								$scope.saveFilePromise.then(function (output) {
									if (output.data.renameError == true) {
										NotificationService.alert('File already exists', 'The file ' + data.FileName + data.FileExtension + ' already exists. Please rename the file, then reupload it.', 'error', {});
									}
									else {
										NotificationService.alert('File Uploaded', data.FileName + data.FileExtension + 'has been uploaded successfully.', 'success', {});

										createMemoForSurveyUpload();

										if (count == file.files.length - 1)
											$scope.close();
									}
								}, function (err) {
									alert(JSON.stringify(err));
									NotificationService.alert(' File Failed', 'Failed to upload ' + data.FileName + data.FileExtension + ".", 'error', {});
								});
							}
						})
						fr.readAsArrayBuffer(file.files[i], i);
					}
					else {
						NotificationService.alert('Error: File size too big.', 'A file is over 50MB and cannot be uploaded. Please resize your file or contact an administrator.')
					}
				}
			}
			else {
				NotificationService.alert('Upload Failed', 'No file found to upload', 'error');
			}
		};

		function createMemoForSurveyUpload() {
			var recipients = [];
			var item = {};
			item.Id = $scope.surveyGroupId;
			item.Type = 'Group';
			recipients.push(item);
			
			var memo = {
				Lead: $scope.CurrentProfile,
				Message: 'Survey Uploaded',
				Subject: 'Survey uploaded for sale order: ' + $scope.CurrentProfile,
				MemoType: 'Reminder',
				SentFromPath: 'sales-order',
				SentFromId: $scope.CurrentProfile,
				Recipients: recipients,
				Parent: null
			}
			$scope.AddingMemoPromise = ($rootScope.onLineFlag) ? EnquiryModalService.addMemo(memo) : IpadService.sendMemo(memo);
			$scope.AddingMemoPromise.then(function (output) {
				if (output) {
					NotificationService.alert('Memo Created', 'Automatic Survey Upload memo has been created', 'success', {});
				}
			}, function (error) {
				NotificationService.alert('Memo Failed', 'Unable to send the Survey Upload memo', 'error');
			});
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('AddRemakeFile', ["$scope", "$rootScope", "$modalInstance", "NotificationService", "EnquiryModalService", "data", function ($scope, $rootScope, $modalInstance, NotificationService, EnquiryModalService, data) {

		$scope.remakeGroupId = 0;

		$scope.init = function () {
			if ($rootScope.SynoSid == "") {
				NotificationService.alert('Authorization Error', 'User has not been given permision to upload files', 'error', {});
				$scope.close();
			}
			$scope.CurrentProfile = data.CurrentProfile;
			$scope.RequestArea = data.RequestArea;
			$scope.getGroup();
		};

		$scope.getGroup = function () {
			var data = {}
			data.Pagination = {};
			data.Pagination.StartPage = 0;
			data.Pagination.PageSize = 100;
			$scope.GettingGroupListPromise = ($rootScope.onLineFlag) ? EnquiryModalService.getGroup(data) : IpadService.getAllGroups();
			$scope.GettingGroupListPromise.then(function (output) {
				$scope.GroupList = ($rootScope.onLineFlag) ? output.data.UserGroupList : output;
				if ($scope.GroupList != null) {
					$scope.GroupList.forEach(function (Name) {
						if (Name.GroupName == "Survey/Remake Upload")
							$scope.remakeGroupId = Name.Id;
					});

				}

			}, function (error) {
				NotificationService.alert('Group Error', 'Failed to retrieve the list of User groups.', 'error', {});
			});
		};

		$scope.SendFile = function () {
			// SBLINDSUAT
			var file = document.getElementById('FileUpload');
			var contents = "";
			if (file.files.length > 0) {
				var count = -1;
				for (var i = 0; i < file.files.length; i++) {
					if (file.files[i].size <= 52428800) {//Currently limiting upload to 50MB
						var fr = new FileReader;
						fr.onload = (function (e) {
							count++;
							contents = e.target.result;
							var data = {
								CurrentProfile: $scope.CurrentProfile,
								FileExtension: $rootScope.MimeArray.find(arr => arr.mime === file.files[count].type).ext,
								FileName: file.files[count].name.replace(/\.[^/.]+$/, ""),
								RequestArea: $scope.RequestArea
							};

							var reader = new FileReader();
							reader.readAsDataURL(file.files[count]);
							reader.onload = function () {
								data.FileContent = reader.result.split(',')[1];

								//communicate with server
								$scope.saveFilePromise = EnquiryModalService.AddFiles(data);
								$scope.saveFilePromise.then(function (output) {
									if (output.data.renameError == true) {
										NotificationService.alert('File already exists', 'The file ' + data.FileName + data.FileExtension + ' already exists. Please rename the file, then reupload it.', 'error', {});
									}
									else {
										NotificationService.alert('File Uploaded', data.FileName + data.FileExtension + 'has been uploaded successfully.', 'success', {});

										createMemoForRemakeUpload();

										if (count == file.files.length - 1)
											$scope.close();
									}
								}, function (err) {
									alert(JSON.stringify(err));
									NotificationService.alert(' File Failed', 'Failed to upload ' + data.FileName + data.FileExtension + ".", 'error', {});
								});
							}
						})
						fr.readAsArrayBuffer(file.files[i], i);
					}
					else {
						NotificationService.alert('Error: File size too big.', 'A file is over 50MB and cannot be uploaded. Please resize your file or contact an administrator.')
					}
				}
			}
			else {
				NotificationService.alert('Upload Failed', 'No file found to upload', 'error');
			}
		};

		function createMemoForRemakeUpload() {
			var recipients = [];
			var item = {};
			item.Id = $scope.remakeGroupId;
			item.Type = 'Group';
			recipients.push(item);

			var memo = {
				Lead: $scope.CurrentProfile,
				Message: 'Remake Uploaded',
				Subject: 'Remake form uploaded for sale order: ' + $scope.CurrentProfile,
				MemoType: 'Reminder',
				SentFromPath: 'sales-order',
				SentFromId: $scope.CurrentProfile,
				Recipients: recipients,
				Parent: null
			}
			$scope.AddingMemoPromise = ($rootScope.onLineFlag) ? EnquiryModalService.addMemo(memo) : IpadService.sendMemo(memo);
			$scope.AddingMemoPromise.then(function (output) {
				if (output) {
					NotificationService.alert('Memo Created', 'Automatic Remake Upload memo has been created', 'success', {});
				}
			}, function (error) {
				NotificationService.alert('Memo Failed', 'Unable to send the Remake Upload memo', 'error');
			});
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('CreatePaymentController', ["$scope", "$modalInstance", "NotificationService", "ParamService", "SaleOrderService", "data", "$filter", function ($scope, $modalInstance, NotificationService, ParamService, SaleOrderService, data, $filter) {

		$scope.filteredPaymentTypes = [];

		$scope.init = function () {
			getPaymentParams();
			$scope.type = data.type;
			$scope.PickList = data.PickList;

			var paymentTypes = $filter('picklistFilter')($scope.PickList, 'ddmPaymentTypes');
			paymentTypes.forEach(function (arrayItem) {
				if (arrayItem.ShowInPortal == true) {
					$scope.filteredPaymentTypes.push(arrayItem);
				}
			});

			if (data.Payment == null) { //triggers when entering from lead profiles. need the data to take a deposit / update the sale order again.
				$scope.FetchPaymentDetailsPromise = SaleOrderService.FetchPaymentDetails({ OrderId: data.OrderId });
				$scope.FetchPaymentDetailsPromise.then(function (output) {
					data.Payment = output.data.PaymentDetails;
					sortPayment();
				})
			}
			else {
				sortPayment();
			}
		};

		function sortPayment() {
			console.debug(data.Payment);
			if ($scope.type == 'Deposit') {
				$scope.Payment = {
					Id: data.PaymentId,
					SaleOrderId: data.OrderId,
					Amount: data.Payment.Deposit,
					orderTotal: data.OrderTotal,
					Deposit: true,
					PaymentNotTaken: false,
					TotalPayments: data.Payment.TotalPayments,
					Type: data.Payment.PaymentMethod,
					PaymentDate: data.Payment.PaymentDate
				};

			} else if ($scope.type == 'Balance') {
				$scope.Payment = {
					Id: data.PaymentId,
					SaleOrderId: data.OrderId,
					orderTotal: data.OrderTotal,
					Amount: data.Payment.Balance,
					Deposit: false,
					PaymentNotTaken: false,
					TotalPayments: data.Payment.TotalPayments,
					Type: data.Payment.PaymentMethod,
					PaymentDate: data.Payment.PaymentDate
				};
			} else {
				$scope.Payment = {
					Id: data.PaymentId,
					TotalPayments: data.Payment.TotalPayments,
					orderTotal: data.OrderTotal,
					SaleOrderId: data.OrderId
				};
			}

			if ($scope.Payment.orderTotal < data.Payment.TotalPayments) {
				$scope.TotalPaymentsColor = {
					"color": "white",
					"background-color": "#e75a5a",
					"border": "#e75a5a"
				};
			} else {
				$scope.TotalPaymentsColor = {
					"color": "white",
					"background-color": "#14aa64",
					"border": "#14aa64"
				};
			}



			if (data.PaymentId)
				getPaymentDetails(data.PaymentId);
		}

		$scope.currencyFields = function (inputValue, inputRoute) {
			currency(inputValue, inputRoute);
		}

		function currency(inputValue, inputRoute) {
			var strCurrency = inputValue.toString();
			if (strCurrency.substring(0, 1) == '+')
				strCurrency = strCurrency.substring(1, strCurrency.length);
			if (strCurrency.substring(0, 1) == '-' && inputRoute != 'Balance')
				NotificationService.alert('Negative Number Detected', 'The number inside ' + inputRoute + ' is negative. Please input a non negative number.', 'error', {});
			else if (strCurrency != '' || strCurrency != 0) {
				if (strCurrency / strCurrency == 1 || strCurrency == 0) {
					if (strCurrency.includes('.')) {
						if (inputValue.length < 3) {
							var cutCurrency = strCurrency;
						}
						else {
							var cutCurrency = strCurrency.slice((strCurrency.length - 3), strCurrency.length);
						}
						if (cutCurrency.substring(0, 1) != '.') {
							if (cutCurrency.substring(1, 2) != '.') {
								if (cutCurrency.substring(2, 3) != '.') {
									NotificationService.alert('Invalid Item', 'Invalid ' + inputRoute + ' detected. Please input a valid amount of currency.', 'error', {});
								}
								else
									strCurrency = strCurrency + '00';
							}
							else if (inputValue.length < 3)
								strCurrency = strCurrency + '00';
							else
								strCurrency = strCurrency + '0';
						}
						else if (inputValue.length < 3)
							strCurrency = '0' + strCurrency + '0';
						else if (strCurrency.length == 3)
							strCurrency = '0' + strCurrency;
					}
					else
						strCurrency = strCurrency + '.00';
				}
				else
					NotificationService.alert('Invalid Item', 'Unexpected values detected inside ' + inputRoute + '. Please input a valid amount of currency.', 'error', {});
			}
			else
				strCurrency = '';

			if (inputRoute == "Amount")
				$scope.Payment.Amount = strCurrency;
			else if (inputRoute == "PartPay90")
				$scope.Payment.PartPayment90 = strCurrency;
			else if (inputRoute == "PartPay10")
				$scope.Payment.PartPayment10 = strCurrency;
			return strCurrency;
		}

		function getPaymentParams() {
			$scope.getParamByEntityAndFieldPromise = ParamService.getParamByEntityAndField("SaleOrder", "PaymentType");
			$scope.getParamByEntityAndFieldPromise.then(function (output) {
				$scope.PaymentParams = output.data.Params;
			}, function (error) {
				NotificationService.alert('Params\' Not Retrieved', 'The requested parameter were not received.', 'error', {});
			});
		};

		function getPaymentDetails(id) {
			$scope.getPaymentPromise = SaleOrderService.getPayment(id);
			$scope.getPaymentPromise.then(function (output) {
				$scope.Payment = output.data;
				console.debug($scope.Payment);

				// Check whether to lock payment method
				if ($scope.Payment != undefined && $scope.Payment.Type != undefined) {
					// Check against payment types for ones set not to show in portal
					var paymentTypes = $filter('picklistFilter')($scope.PickList, 'ddmPaymentTypes');
					$scope.filteredPaymentTypes = [];
					paymentTypes.forEach(function (arrayItem) {
						if (arrayItem.Id == $scope.Payment.Type && arrayItem.ShowInPortal != true) {
							$scope.filteredPaymentTypes.push(arrayItem);
						}
						if (arrayItem.ShowInPortal == true) {
							$scope.filteredPaymentTypes.push(arrayItem);
						}
					});
				}

			}, function (error) {
				NotificationService.alert('Payment Error', 'There was an error getting the payment.', 'error', {});
			});
		};

		$scope.save = function () {
			if (($scope.Payment.AuthorisationCode == "" || $scope.Payment.AuthorisationCode == undefined) && $scope.Payment.PaymentNotTaken == false) {
				NotificationService.alert('Form Error', 'Payment must either have a Authorisation Code or be marked as not taken yet.', 'error', {});
			}
			else {
				if ($scope.Payment.Id) {
					$scope.savePaymentPromise = SaleOrderService.updatePayment($scope.Payment);
				} else {
					$scope.savePaymentPromise = SaleOrderService.createPayment($scope.Payment);
				};
				$scope.savePaymentPromise.then(function (output) {
					NotificationService.alert('Payment Saved', 'Payment changes were made.', 'success', {});
					$modalInstance.close();
				}, function (error) {
					NotificationService.alert('Payment Error', 'There was an error saving the payment.', 'error', {});
				});
			}
		};

		$scope.close = function () {
			$scope.TotalPaymentsColor = {};
			$modalInstance.dismiss();
		};
	}])
	.controller('SurveyMaintenanceController', ["$scope", "$modal", "$rootScope", "$location", "$modalInstance", "NotificationService", "PickListService", "SaleOrderService", "StaffService", "IpadService", "AppointmentService", "data", "$timeout", function ($scope, $modal, $rootScope, $location, $modalInstance, NotificationService, PickListService, SaleOrderService, StaffService, IpadService, AppointmentService, data, $timeout) {

		$scope.selectedForAppointment = [];
		$scope.previousFitter = "";

		$scope.init = function () {
			getStaff();
			$scope.selectedForAppointment = [];
			$scope.searchedRecipient = "";

			getParams();
			$scope.existingMaintenance = false;
			$scope.Today = new Date();
			$scope.openPopups = {};
			$scope.createAppointment = false;
			$scope.AppointmentTime = {};
			$scope.AppointmentTime.Start = moment().subtract(1, "hours");
			$scope.AppointmentTime.End = moment();
			$scope.CustomerName = data.Customer;
			$scope.drp_options = {
				"timePicker": true,
				"timePicker12Hour": false,
				"opens": "left",
				"startDate": $scope.AppointmentTime.Start,
				"endDate": $scope.AppointmentTime.End,
				format: 'DD/MM/YYYY',
				showDropdowns: true

			};
			$scope.toggleSwitch = false;

			$scope.Maintenance = {
				Id: data.MaintenanceId,
				SaleOrderId: data.OrderId,
				Ref: data.Ref,
				Reported: null,
				Resolved: null
			};

			if (data.MaintenanceId) {
				getSurveyMaintenance(data.MaintenanceId);
			}
			else {
				$scope.AppointmentExists = 0;
			}
				
		};

		$scope.$watch('Maintenance.FitterId', function (newValue, oldValue) {
			if (newValue !== oldValue && newValue != null) {			
				addStaffToSelectedRecipient(newValue);
			}
		});

		$scope.$watchCollection('[AppointmentTime.Start, AppointmentTime.End]', function () {
			$scope.displayStartDate = moment($scope.AppointmentTime.Start).format('DD/MM/YYYY @ H:mm:ss');
			$scope.displayEndDate = moment($scope.AppointmentTime.End).format('DD/MM/YYYY @ H:mm:ss');
		});

		function addStaffToSelectedRecipient(staffId) {
			// Assuming allStaffList is available in your $scope, find the selected staff
			var selectedStaff = $scope.allStaffList.find(function (staff) {
				return staff.Id == staffId;
			});

			if (selectedStaff) {
				//console.log("Selected staff ID:", selectedStaff.Id);
				getCurrentFitterByStaffId(selectedStaff.Id);
			}
		}

		function getSurveyMaintenance(id) {
			$scope.getSurveyMaintenancePromise = ($rootScope.onLineFlag) ? SaleOrderService.getSurveyMaintenance(id) : IpadService.getSurveyMaintenance(id);
			$scope.getSurveyMaintenancePromise.then(function (output) {
				const data = ($rootScope.onLineFlag) ? output.data : output;
				$scope.existingMaintenance = true;
				$scope.Maintenance = data;
				$scope.AppointmentExists = data.Appointment
				if ($scope.AppointmentExists != 0) {
					$scope.createAppointment = true;
				}
				$scope.Maintenance.FitterId = $scope.Maintenance.Fitter ? $scope.Maintenance.Fitter.Id : '';
				$scope.selectedForAppointment = data.Attendees;
				$scope.AppointmentTime.Start = moment($scope.Maintenance.Start);
				$scope.AppointmentTime.End = moment($scope.Maintenance.End);

				// Delay processing to ensure $scope.PickList is populated
				$timeout(function () {
					const typeFound = $scope.PickList.find(p => p.PickListEntry === $scope.Maintenance.Type);
					if (typeFound) {
						if (typeFound.IsDeleted) {
							// Flip the flag to temporarily show it as not deleted
							typeFound.IsDeleted = false;
						}
						$scope.Maintenance.Type = typeFound;
					}

					const hardwaretypeFound = $scope.PickList.find(p => p.PickListEntry === $scope.Maintenance.HardwareType);
					if (hardwaretypeFound) {
						if (hardwaretypeFound.IsDeleted) {
							// Flip the flag to temporarily show it as not deleted
							hardwaretypeFound.IsDeleted = false;
						}
						$scope.Maintenance.HardwareType = hardwaretypeFound;
					}
				}, 0);

			}, function (error) {
				NotificationService.alert('Maintenance Error', 'There was an error getting the maintenance.', 'error', {});
			});
		};

		function getCurrentFitter(orderId) {
			$scope.getCurrentFitterPromise = SaleOrderService.getCurrentFitter(orderId);
			$scope.getCurrentFitterPromise.then(function (output) {
				const data = output.data;
				$scope.selectedForAppointment = data.Attendees;
			}, function (error) {
				NotificationService.alert('Maintenance Error', 'There was an error getting the current fitter.', 'error', {});
			});
		}

		function getCurrentFitterByStaffId(staffId) {
			$scope.getCurrentFitterByStaffIdPromise = SaleOrderService.getCurrentFitterByStaffId(staffId);
			$scope.getCurrentFitterByStaffIdPromise.then(function (output) {
				const data = output.data;
				$scope.selectedForAppointment = data.Attendees;
			}, function (error) {
				NotificationService.alert('Maintenance Error', 'There was an error getting the current fitter by staff id.', 'error', {});
			});
		}

		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		function getStaff() {
			$scope.GetAllStaffPromise = ($rootScope.onLineFlag) ? StaffService.getAllStaff() : IpadService.getAllStaff();
			$scope.GetAllStaffPromise.then(function (output) {
				$scope.allStaffList = ($rootScope.onLineFlag) ? output.data.StaffList : output;
				$scope.recipientList = ($rootScope.onLineFlag) ? output.data.StaffList : output;
				addStaffToSelectedRecipient($scope.Maintenance.FitterId);
			}, function (error) {
				NotificationService.alert('Staff Error', 'There was an error gathering all the data, please try again.', 'error', {});
			});
		};

		function getParams() {
			$scope.PicklistPromise = ($rootScope.onLineFlag) ? PickListService.getPickListByPickListName(['ddmServiceRequest', 'Supplier', 'ddmProductType', 'ddmHardwareType', 'ReasonForUpdate']) : IpadService.getPicklist();
			$scope.PicklistPromise.then(function (output) {
				$scope.PickList = ($rootScope.onLineFlag) ? output.data.PickListEntries : output;
				$scope.ServiceQuotable = $scope.PickList.filter(x => x.PickListEntry == 'Chargeable Visit')[0];
			}, function (error) {
				NotificationService.alert('Pick List Error', 'There was an error gathering all the pick list data, please try again.', 'error', {});
			});
		};

		$scope.save = function () {
			$scope.Maintenance.Start = moment($scope.AppointmentTime.Start);
			$scope.Maintenance.End = moment($scope.AppointmentTime.End);
			if ($scope.Maintenance.Type) {
				$scope.Maintenance.Type = $scope.Maintenance.Type.PickListEntry;
			}
			if ($scope.Maintenance.HardwareType) {
				$scope.Maintenance.HardwareType = $scope.Maintenance.HardwareType.PickListEntry;
			}			
			if (!$rootScope.onLineFlag) {
				$scope.Maintenance.Start = $scope.Maintenance.Start.toISOString();
				$scope.Maintenance.End = $scope.Maintenance.End.toISOString();
			}
			if ($scope.existingMaintenance)
				$scope.SaveMaintenancePromise = ($rootScope.onLineFlag) ? SaleOrderService.updateSurveyMaintenance($scope.Maintenance) : IpadService.updateSurveyMaintenance($scope.Maintenance);
			else
				$scope.SaveMaintenancePromise = ($rootScope.onLineFlag) ? SaleOrderService.createSurveyMaintenance($scope.Maintenance) : IpadService.saveNewSurveyMaintenance($scope.Maintenance);

			$scope.SaveMaintenancePromise.then(function (output) {
				const data = ($rootScope.onLineFlag) ? output.data : output;
				var appointmentExists = ($rootScope.onLineFlag) ? data.Appointment : data.Appointment;
				if ($scope.createAppointment || $scope.existingMaintenance) {
					if ($scope.existingMaintenance && appointmentExists == true) {
						UpdateAppointment(data.Id);
						NotificationService.alert('Maintenance Saved', 'Maintenance changes were made.', 'success', {});
					}
					else if ($scope.createAppointment == true) {
						if ($scope.Maintenance.FitterId == null) {
							NotificationService.alert('Appointment Error', 'Cannot create an appointment without a fitter assigned. Please try again after assigning a fitter', 'error', {});
						}
						else {
							MakeAppointment($scope.CustomerName, $scope.Maintenance.SaleOrderId, data.Id);
							NotificationService.alert('Maintenance Saved', 'Maintenance changes were made.', 'success', {});
						}
					}
					else {
						NotificationService.alert('Maintenance Saved', 'Maintenance changes were made.', 'success', {});
						$modalInstance.close();
					}
				}
				else {
					NotificationService.alert('Maintenance Saved', 'Maintenance changes were made.', 'success', {});
					$modalInstance.close();
				}
			}, function (error) {
				NotificationService.alert('Maintenance Error', 'There was an error saving the maintenance.', 'error', {});
			});
		};

		function UpdateAppointment(id) {
			const data = {
				Type: 'Maintenance',
				Id: id,
				From: $scope.Maintenance.Start,
				To: $scope.Maintenance.End,
				FitterId: $scope.Maintenance.FitterId,
				Attendees: $scope.selectedForAppointment,
			};
			$scope.updatingAppointmentPromise = ($rootScope.onLineFlag) ? AppointmentService.UpdateAppointmentBooking(data) : IpadService.updateAppointment(data);
			$scope.updatingAppointmentPromise.then(success => {
				$modalInstance.close();
			}, err => {
				NotificationService.alert('Appointment Error', 'There was an error updating the associated appointment with this maintenance booking.', 'error', {});
			});
		};

		function MakeAppointment(Customer, Id, MaintenanceId) {
			var Maintenance = $scope.Maintenance;
			var Title = Maintenance.MaintenanceType + ' Blind type ' + Maintenance.Type + ' for ' + Customer;
			var appointment = {
				Title: Title,
				Start: moment(Maintenance.Start),
				End: moment(Maintenance.End),
				Notes: Maintenance.Details,
				RelatedObjectId: Id,
				RelatedObjectType: 'Service-' + Maintenance.MaintenanceType,
				Attendees: $scope.selectedForAppointment,
				MaintenanceId: MaintenanceId

			}
			$scope.creatingAppointmentPromise = ($rootScope.onLineFlag) ? AppointmentService.MakeAppointment(appointment) : IpadService.addAppointment(appointment)
			$scope.creatingAppointmentPromise.then((output) => {
				NotificationService.alert('Appointment Booked', 'Appointment has sucessfully been booked.', 'success', {});
				$modalInstance.close();
			}, (error) => {
				NotificationService.alert('Appointment Error', 'There was an error updating the associated appointment with this maintenance booking.', 'error', {});
			})
		}

		$scope.AddStaff = function (name, index) {
			$scope.selectedForAppointment.push(name);
			$scope.recipientList.splice($scope.recipientList.indexOf(name), 1);
		};

		$scope.RemoveStaff = function (name, index) {
			$scope.recipientList.push(name);
			$scope.selectedForAppointment.splice($scope.selectedForAppointment.indexOf(name), 1);
		};

		$scope.removeSurveyMaintenance = function (MaintenanceId) {
			if (confirm("Are you sure that you want to remove this maintenance?")) {
				var data = {
					Type: "Maintenance",
					To: new Date(),
					From: new Date(),
					Id: MaintenanceId,
					isDeleted: true,
					FitterId: null
				};
				$scope.RemoveMaintenanceAppointmentPromise = ($rootScope.onLineFlag) ? AppointmentService.UpdateAppointmentBooking(data) : updateAppointment.updateAppointment(data);
				$scope.removeMaintenancePromise = ($rootScope.onLineFlag) ? SaleOrderService.removeSurveyMaintenance(MaintenanceId) : IpadService.deleteSurveyMaintenance(MaintenanceId);
				$scope.removeMaintenancePromise.then(function () {
					if ($scope.AppointmentExists == true) {
						$scope.RemoveMaintenanceAppointmentPromise.then(function () {
							$modalInstance.close()
						}, function (error) {
							NotificationService.alert('Maintenance Appointment Error', 'There was an error removing the maintenance appointment.', 'error', {});
						});
					}
					else
						$modalInstance.close()
				}, function (error) {
					NotificationService.alert('Maintenance Error', 'There was an error removing the maintenance.', 'error', {});
				});
			};
		};

		$scope.GoToOrder = function () {
			$location.url('/sales-order/' + $scope.Maintenance.Ref);
			$scope.close();
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};

		$scope.openAddNoteModal = function () {
			openModal('views/modal-templates/add-note.html', 'AddNoteController', {
				ObjectId: $scope.Maintenance.SaleOrderId,
				ObjectName: "Service and Maintenance",
				Note: ""
			});
		};

		function openModal(modalTemplate, Controller, modalData, config) {
			return $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: modalTemplate,
				controller: Controller,
				resolve: {
					data: function () {
						return modalData;
					},
					Id: function () {
						return modalData;
					},
					config: function () {
						return config
					}
				}
			});
		};
	}])
	.controller('RemakeController', ["$scope", "$rootScope", "$modal", "$modalInstance", "NotificationService", "PickListService", "SaleOrderService", "AppointmentService", "$location", "data", function ($scope, $rootScope, $modal, $modalInstance, NotificationService, PickListService, SaleOrderService, AppointmentService, $location, data) {

		$scope.init = function () {
			getParams();

			$scope.Remake = {
				Id: data.RemakeId,
				SaleOrderId: data.OrderId
			};

			$scope.appointmentBooked = '';

			if (data.RemakeId)
				getRemake(data.RemakeId);

			$scope.AppointmentExists = data.Appointment;
			$scope.AppointmentDeleted = data.AppointmentDeleted;
			$scope.openPopups = {};
		};

		//DatePicker
		$scope.open = function ($event, variable) {
			$event.preventDefault();
			$event.stopPropagation();

			for (var k in $scope.openPopups) {
				$scope.openPopups[k] = false;
			};
			$scope.openPopups[variable] = true;
		};

		$scope.MakeRemakeAppointments = function (input) {
			if (input == undefined)
				input = 0;
			data.Update = input;
			$scope.Remake.ReturnVisit = $rootScope.ServiceAppointment;
			var modalInstance = $modal.open({
				animation: true,
				size: 'lg',
				templateUrl: 'views/modal-templates/book-appointment.html',
				controller: 'CreateAppointmentModalCtrl',
				resolve: {
					Data: function () {
						return {
							Type: 'Remake',
							Update: data.Update,
							RemakeId: data.RemakeId,
							ObjectID: data.OrderId,
							Ref: data.ExternalRef,
							sender: data.Odrerinfo.Sender,
							SelectedRecipient: data.Odrerinfo.SelectedRecipient,
							Customer: data.Odrerinfo.CustomerName
						}
					}
				}
			});

			modalInstance.result.then(function (data) {
				$scope.Remake.ReturnVisit = moment(data.Start) || moment(data.To);
				$scope.Remake.RemakeAppointment = data.Id;
				$scope.appointmentBooked = (data.Start || data.To) + ' - ' + (data.End || data.From);
				$scope.Appointment = data;
				$scope.appointmentRemoved = data.appointmentRemoved;
			});
		}

		function getRemake(id) {
			$scope.getRemakePromise = SaleOrderService.getRemake(id);
			$scope.getRemakePromise.then(function (output) {
				$scope.Remake = output.data;
			}, function (error) {
				NotificationService.alert('Remake Error', 'There was an error getting the remake.', 'error', {});
			});
		};

		function getParams() {
			$scope.PicklistPromise = PickListService.getPickListByPickListName(['ddmRemakeReason', 'Urgency']);
			$scope.PicklistPromise.then(function (output) {
				$scope.PickList = output.data.PickListEntries;
			}, function (error) {
				NotificationService.alert('Pick List Error', 'There was an error gathering all the pick list data, please try again.', 'error', {});
			});
		};

		$scope.save = function () {
			if ($scope.Appointment == null) {
				createRemake();
			}
			else {
				if ($scope.AppointmentExists == 0) {
					$scope.Remake.AppointmentCreated = true;
					$scope.AppointmentPromise = AppointmentService.MakeAppointment($scope.Appointment);
				}
				else {
					$scope.Remake.AppointmentCreated = false;
					$scope.AppointmentPromise = AppointmentService.UpdateAppointmentBooking($scope.Appointment);
				}

				$scope.AppointmentPromise.then(function (output) {
					$scope.Remake.RemakeAppointment = output.data.Appointment.Id;
					if ($scope.Appointment.To != undefined)
						$scope.Remake.ReturnVisit = $scope.Appointment.To;
					NotificationService.alert('Appointment Created', 'Appointment successfully created for this remake.', 'success', {});
					createRemake();
				}, function (e) {
					NotificationService.alert('Appointment Error', 'There was an error creating this appointment.', 'error', {});
				});
			}
		};

		function createRemake() {
			if ($scope.Remake.Id)
				$scope.SaveRemakePromise = SaleOrderService.updateRemake($scope.Remake);
			else
				$scope.SaveRemakePromise = SaleOrderService.createRemake($scope.Remake);

			$scope.SaveRemakePromise.then(function (output) {
				NotificationService.alert('Remake Saved', 'Remake changes were made.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Remake Error', 'There was an error saving the remake.', 'error', {});
			});
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('LinkedEnquiriesController', ["$scope", "$location", "$modalInstance", "EnquiryModalService", "NotificationService", "data", function ($scope, $location, $modalInstance, EnquiryModalService, NotificationService, data) {

		$scope.init = function () {
			$scope.Id = data;
			$scope.LinkedEnquiries = [];
			$scope.LinkedEnquiriesPageNumber = 0;
			$scope.LinkedEnquiriesPagination = {
				StartPage: $scope.LinkedEnquiriesPageNumber,
				PageSize: 25
			};
		};

		$scope.$watch('LinkedEnquiriesPageNumber', function (n, o) {
			if (n != undefined)
				$scope.GetLinkedEnquiries();
		});

		$scope.GetLinkedEnquiries = function () {
			var data = {
				LeadId: $scope.Id,
				Pagination: $scope.LinkedEnquiriesPagination
			};
			$scope.gettingLinkedEnquiriesPromise = EnquiryModalService.getLinkedEnquiries(data);
			$scope.gettingLinkedEnquiriesPromise.then(output => {
				$scope.LinkedEnquiries = output.data.Results;
				$scope.LinkedEnquiriesPagination = output.data.PaginationStatus;
			}, err => {
				NotificationService.alert('Linked Enquiries Error', 'There was an error getting the linked enquiries.', 'error', {});
			});
		};

		$scope.goToLead = function (id) {
			$location.url('/enquiry-details/' + id);
			$scope.close();
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('IssueLeadModalController', ["$scope", "$modalInstance", "EnquiryModalService", "LeadProfileService", "NotificationService", "data", function ($scope, $modalInstance, EnquiryModalService, LeadProfileService, NotificationService, data) {

		$scope.init = function () {
			$scope.Data = data;
			$scope.selectedStaff = null;
			$scope.StaffList = [];
			getStaff();
		};

		function getStaff() {
			$scope.GettingStaffListPromise = EnquiryModalService.getStaff();
			$scope.GettingStaffListPromise.then(function (output) {
				output.data.StaffList.forEach((item) => {
					if (item.Roles.length != 0)
						if (item.Roles.filter(r => r.Name == 'Sales Person' || r.Name == 'Sales Person/Fitter' || r.Name == 'Sales Manager').length > 0)
							$scope.StaffList.push(item);
				})

				if ($scope.Data.LeadPostCode)
					GetSalesPersonBySaleArea();
			}, function (error) {
				NotificationService.alert('Staff Error', 'Failed to retrieve the list of staff.', 'error', {});
			});
		};

		function GetSalesPersonBySaleArea() {
			$scope.gettingSalesPersonBySaleAreaPromise = EnquiryModalService.getSalesPersonBySaleArea($scope.Data.LeadPostCode);
			$scope.gettingSalesPersonBySaleAreaPromise.then((output) => {
				if (output.data.SalesPersonId)
					$scope.selectedStaff = output.data.SalesPersonId;
				else
					NotificationService.alert('Sales Person Issue', 'Unable to get the sales person for this sale area. This could be because the sale area doesn\'t exist or there is no sales person associated with this sale area.', 'warning', {});
			}, (error) => {
				NotificationService.alert('Sales Person Error', 'Failed to retrieve the sales person by sale area.', 'error', {});
			})
		}

		$scope.IssueLead = function () {
			var staff = $scope.StaffList.find(function (element) {
				if (element.Id == $scope.selectedStaff)
					return element;
			});
			$scope.Data.SalesPerson = staff.Id;
			$scope.IssueEnquiryPromise = LeadProfileService.issueLead($scope.Data);
			$scope.IssueEnquiryPromise.then(function (output) {
				NotificationService.alert('Lead Issued', 'Successfully issued this lead to ' + staff.FirstName + ' ' + staff.LastName + '.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Issuing Error', 'There was an error issuing this lead to ' + staff.FirstName + ' ' + staff.LastName + '.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('DeDuplicateQuickViewController', ["$scope", "$modalInstance", "$location", "ProspectService", "LeadProfileService", "PickListService", "NotificationService", "Data", function ($scope, $modalInstance, $location, ProspectService, LeadProfileService, PickListService, NotificationService, Data) {
		$scope.init = function () {
			$scope.Id = Data.Id;
			$scope.Type = Data.Type;
			$scope.DisplayingDetails = Data.Right;
			$scope.data = {};
			$scope.getPicklists();

			if ($scope.Type == 'Prospect')
				$scope.GetProspect($scope.Id);
			else
				$scope.GetLead($scope.Id);
		};

		$scope.getPicklists = function () {
			var data = ['Title', 'Status'];
			$scope.PicklistPromise = PickListService.getPickListByPickListName(data);
			$scope.PicklistPromise.then((output) => {
				$scope.picklist = output.data.PickListEntries;
			}, (erorr) => {
				NotificationService.alert('Picklist Error', 'There was an error getting the picklists.', 'error', {});
			});
		};

		$scope.GetProspect = function (id) {
			$scope.prospectPromice = ProspectService.getProspectDetail(id);
			$scope.prospectPromice.then((output) => {
				$scope.data = output.data.ProspectDetail;
			}, (error) => {
				NotificationService.alert('Prospect Error', 'There was an error getting the details of this prospect.', 'error', {});
			})
		};

		$scope.GetLead = function (id) {
			$scope.GetEnquiryPromise = LeadProfileService.getEnquiryById(id);
			$scope.GetEnquiryPromise.then((output) => {
				$scope.data = output.data.LeadDetail;
			}, (error) => {
				NotificationService.alert('Lead Error', 'There was an error getting the details of this lead.', 'error', {});
			})
		};

		$scope.UpdateProspect = function () {
			$scope.UpdatingProspectPromise = ProspectService.saveProspect({ ProspectDetail: $scope.data });
			$scope.UpdatingProspectPromise.then((output) => {
				NotificationService.alert('Prospect Updated', 'Successfully updated the De-dupe status of this prospect.', 'success', {});
			}, (error) => {
				NotificationService.alert('Update Error', 'Unable to update the De-dupe status of this prospect.', 'error', {});
			});
		};

		$scope.openFullView = function (Id) {
			$location.path('/prospect-details/' + Id);
			$scope.close();
		}
		$scope.close = function () {
			$modalInstance.dismiss();
		}
	}])
	.controller('UpdateAlternativeContactItemController', ["$scope", "$modalInstance", "EnquiryModalService", "NotificationService", "PickListService", "data", function ($scope, $modalInstance, EnquiryModalService, NotificationService, PickListService, data) {

		$scope.init = function () {
			$scope.Contact = data.Contact;
			console.log($scope.Contact);
			getPicklist();
		};

		function getPicklist() {
			$scope.PicklistPromise = PickListService.getPickListByPickListName(['ContactType', 'JobTitle']);
			$scope.PicklistPromise.then(function (output) {
				$scope.PickList = output.data.PickListEntries;
			}, function (error) {
				NotificationService.alert('Pick List Error', 'There was an error gathering all the pick list data, please try again.', 'error', {});
			});
		};

		$scope.UpdateContact = function () {
			var data = {
				Contact: {
					Id: $scope.Contact.Id,
					ContactName: $scope.Contact.ContactName,
					TypeOfContactata: $scope.Contact.TypeOfContactata,
					ContactData: $scope.Contact.ContactData,
					JobTitle: $scope.Contact.JobTitle
				}
			};
			$scope.updatingAlternativeContactPromise = EnquiryModalService.updateAlternativeContactItem(data);
			$scope.updatingAlternativeContactPromise.then(function (success) {
				NotificationService.alert('Contact Updated', 'This alternative contact item has been sucessfully updated.', 'success', {});
				$modalInstance.close();
			}, function (err) {
				NotificationService.alert('Contact Update Error', 'There was an error when attemtping to update this contact.', 'error', {});
			});
		};

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])
	.controller('SynologyLoginController', ["$rootScope", "$scope", "$modalInstance", "EnquiryModalService", "NotificationService", function ($rootScope, $scope, $modalInstance, EnquiryModalService, NotificationService) {

		$scope.init = function () {
			$scope.Sid = $rootScope.SynoSid;
			$scope.SynoUsername = $rootScope.SynoUsername;
			$scope.SynoPassword = "";
		}

		$scope.login = function (Username, Password) {
			if ($rootScope.SynoSid != "") {
				NotificationService.alert('Login Error', 'User is already logged in!.', 'warning', {});
			}
			else {
				var data = {
					Username: Username,
					Password: Password
				};
				$scope.loginPromise = EnquiryModalService.SynoLogin(data);
				$scope.loginPromise.then(function (output) {
					if (output.data.success == true) {
						$rootScope.SynoSid = output.data.sid;
						$rootScope.SynoUsername = Username;
						NotificationService.alert('Login Successful', 'Successfully logged into Cloud Storage.', 'success', {});
						$scope.close();
					}
					else {
						NotificationService.alert('No Connection', 'Could not communicate with Cloud Storage server. Please try again later.', 'warning', {});
					}
				});
			}
		}

		$scope.logout = function () {
			if ($rootScope.SynoSid == "") {
				NotificationService.alert('Logout Error', 'User is not logged in!.', 'warning', {});
			}
			else {
				var data = {
					Sid: $rootScope.SynoSid
				};
				// SBLINDSUAT
				//$scope.logoutPromise = EnquiryModalService.SynoLogout(data);
				//$scope.logoutPromise.then(function (output) {
				//	if (output.data == "true") {
				//		$rootScope.SynoSid = "";
				//		$rootScope.SynoUsername = "";
				//		NotificationService.alert('Logout Successful', 'Successfully logged out of Cloud Storage', 'success', {});
				//		$scope.close();
				//	}
				//	else {
				//		NotificationService.alert('Logout Failed', 'Could not logout of Cloud Storage. Please try again later.', 'erorr', {});
				//	}
				//});
			}
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		}
	}])

	.controller('LinkAccount', ["$rootScope", "$scope", "$modalInstance", "EnquiryModalService", "NotificationService", "data", function ($rootScope, $scope, $modalInstance, EnquiryModalService, NotificationService, data) {

		$scope.init = function () {
			$scope.User = data.FirstName + " " + data.LastName;
			$scope.Cloud = {
				UserId: data.UserId,
				Username: '',
				Password: '',
				PasswordConfirm: '',
			}
		}

		$scope.randomString = function (length) {
			var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

			if (!length) {
				length = Math.floor(Math.random() * chars.length);
			}

			var str = '';
			for (var i = 0; i < length; i++) {
				str += chars[Math.floor(Math.random() * chars.length)];
			}
			return str;
		}

		

		$scope.Assign = function () {
			$scope.AssignPromise = EnquiryModalService.SynoLinkAccount($scope.Cloud);
			$scope.AssignPromise.then(function (output) {
				if (output.data == 'true') {
					NotificationService.alert('Link Successful', "Sucessfully linked " + $scope.User + "'s account with the Cloud account " + $scope.Cloud.Username + ".", 'success', {});
					$scope.close();
				}
			}).catch(function () {
				NotificationService.alert('Link Failed', "Account has already been bound to a Cloud Storage account or the Username/Password is incorrect.", 'error', {});
			});
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		}
	}])

	.controller('OfflineSyncUpload', ["$scope", "$modalInstance", "IpadService", function ($scope, $modalInstance, IpadService) {

		$scope.init = function () {
			$scope.pageNumber = 0
			$scope.numOfChanges = 0
			IpadService.getNumOfChanges().then(function (d) {
				$scope.numOfChanges = d;
			})

		}
		$scope.UploadOfflineData = function () {
			IpadService.uploadOfflineData();
		}
		$scope.close = function () {
			$modalInstance.dismiss();
		}
	}])
	.controller('confirmController', ["$scope", "$modalInstance", "data", function ($scope, $modalInstance, data) {

		$scope.init = function () {
			$scope.message = data.message;
		}
		$scope.yes = function () {
			$modalInstance.dismiss({ confirm: true });
		}
		$scope.close = function () {
			$modalInstance.dismiss({ confirm: false });
		}
	}])

	.controller('SurveyHistoryModalController', ["$scope", "$modalInstance", "SaleOrderService", "NotificationService", "data", function ($scope, $modalInstance, SaleOrderService, NotificationService, data) {

		$scope.init = function () {
			$scope.SalesOrderId = data.SaleOrderId;
			$scope.SurveyInUseId = data.SurveyInUseId;
			$scope.Surveys = [];
			$scope.GetSurveyHistory($scope.SalesOrderId);
		};

		$scope.GetSurveyHistory = function (id, selectedSurvey) {
			$scope.gettingSurveyHistoryPromise = SaleOrderService.getSurveyHistory(id);
			$scope.gettingSurveyHistoryPromise.then(function (output) {
				$scope.Surveys = output.data.SurveyHistory;
				if (selectedSurvey)
					$modalInstance.close();
			}, function (error) {
				NotificationService.alert('History Error', 'There was an getting the survey history.', 'error', {});
			})
		};

		$scope.UseThisSurvey = function (id) {
			if (confirm('Selecting this survey will reset all survey related data and order lines on this order and replace with details of this survey. Do you wish to continue?')) {
				$scope.selectingSurveyPromise = SaleOrderService.SelectSurvey({ SurveyId: id, SaleOrderId: $scope.SalesOrderId });
				$scope.selectingSurveyPromise.then(function (output) {
					NotificationService.alert('Survey Selected', 'This survey has been successfully set as in use.', 'success', {});
					$scope.SurveyInUseId = id;
					$scope.GetSurveyHistory($scope.SalesOrderId, true);
				}, function (error) {
					NotificationService.alert('Selection Error', 'There was an error attempting to select this survey.', 'error', {});
				})
			}
		}

		$scope.DeleteThisSurvey = function (id) {
			if (confirm('Are you sure you want to delete this survey?')) {
				$scope.deletingSurveyPromise = SaleOrderService.DeleteSurvey({ SurveyId: id });
				$scope.deletingSurveyPromise.then(function (output) {
					NotificationService.alert('Survey Deleted', 'This survey has been successfully deleted.', 'success', {});
					$scope.GetSurveyHistory($scope.SalesOrderId);
				}, function (error) {
					NotificationService.alert('Deleting Error', 'There was an error attempting to delete this survey.', 'error', {});
				})
			}
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		};
	}])

	.controller('ReturnToHOController', ["$scope", "$location", "$routeParams", "$modalInstance", "NotificationService", "LeadProfileService", "Data", function ($scope, $location, $routeParams, $modalInstance, NotificationService, LeadProfileService, Data) {

		$scope.init = function () {
			$scope.leadId = Data.Ref;
		}

		$scope.ReturnToHO = function () {
			$scope.Submit('M');
		}

		$scope.NoEntry = function () {
			$scope.Submit('R')
		}

		$scope.Submit = function (StatusCode) {
			var data = {
				LeadId: $scope.leadId,
				StatusCode: StatusCode
			};
			console.log(data);
			$scope.SubmitChanges = LeadProfileService.ReturnToHOModal(data);
			$scope.SubmitChanges.then(function (output) {
				NotificationService.alert('Status Updated', 'The status has been updated to ' + output.data.Status + '.', 'success', {});
				$modalInstance.close();
			}, function (error) {
				NotificationService.alert('Error Changing Status', 'There was an error updating the status of this lead.', 'error', {});
			})
		}

		$scope.close = function () {
			$modalInstance.dismiss();
		}
	}])

